import { NextPage } from "next";
import { INextPageProps } from "types";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { languageConfig } from "i18n/language-config";
import { Locales } from "i18n/country";
import PageModularApi from "../../contentful/page-modular-api";
import { PageModular } from "../../contentful/PageModularModule";
import { observer } from "../../app";

import { ModularPageProps } from "domain/CommonModules/ModularPage";
import { EHttpStatus } from "utils/backend-api/error-types";

import dynamic from "next/dynamic";
import { sitemapModularPageUtil } from "utils/sitemap/sitemap-modular-pages-util";
import { useStores } from "../../hooks";

const Error = dynamic(() => import("../_error"), { ssr: false });

const ModularPage = dynamic<ModularPageProps>(() =>
  import("domain/CommonModules/ModularPage").then((mod) => mod.ModularPage)
);

interface IProps extends INextPageProps {
  modularPageExists: boolean;
  modularPage: PageModular;
}
enum PageType {
  Error,
  PageModular,
}

const Index: NextPage<IProps> = observer(({ ...props }) => {
  const { modularPageExists, modularPage } = props;
  const { layoutStore } = useStores();

  if (props.toggles) {
    layoutStore.setToggles(props.toggles);
  }

  let type: PageType;

  if (modularPageExists) {
    type = PageType.PageModular;
  } else {
    type = PageType.Error;
  }

  switch (type) {
    case PageType.PageModular:
      return <ModularPage modularPage={modularPage} />;
    default:
      return <Error statusCode={EHttpStatus.NotFound} />;
  }
});

export const getStaticProps = async ({ res, params: { lang = null } = {} }) => {
  const isStartPageForAllowedLanguage = languageConfig.isLanguageAllowedInPath(lang);

  if (isStartPageForAllowedLanguage) {
    const locale = languageConfig.mapLanguageToLocale(lang);
    const [modularStartPage] = await Promise.all([getModularStartPage(locale)]);

    const isFollow = null;

    const modularStartPageExists = Boolean(modularStartPage);
    return {
      props: {
        ...(await serverSideTranslations(locale, [
          "common",
          "page-index",
          "pages-record-check",
          "page-error",
          "page-pricing",
          "forms",
        ])),
        modularPage: modularStartPage,
        modularPageExists: modularStartPageExists,
        isFollow,
      },
      notFound: !modularStartPageExists,
      revalidate: 60,
    };
  } else {
    const pageName = lang;
    const locale = Locales.USA;

    let modularPage = await getModularPage(locale, pageName);
    const modularPageExists = Boolean(modularPage);

    if (!modularPageExists) {
      if (res) {
        res.statusCode = EHttpStatus.NotFound;
      }
    }
    modularPage = setNullIfFalsy(modularPage);
    return {
      props: {
        ...(await serverSideTranslations(locale, ["common", "page-index", "page-error", "page-pricing", "forms"])),
        modularPage,
        modularPageExists,
        isFollow: false,
      },
      notFound: !modularPage,
      revalidate: 60,
    };
  }
};

export async function getStaticPaths() {
  const langs = languageConfig.getLanguages();
  const paths = langs
    .filter((lang) => lang != "en")
    .map((lang) => ({
      params: { lang },
    }));

  const enModularPages = await sitemapModularPageUtil.getModularPages(Locales.USA);
  const excludedPaths = ["/business", "/careers"];
  const filteredEnModularPages = enModularPages.filter((pageSlug) => !excludedPaths.includes(pageSlug));
  filteredEnModularPages.forEach((pageSlug) => {
    const slugContent = pageSlug.replaceAll("/", "");
    if (slugContent && !langs.includes(slugContent)) {
      paths.push({ params: { lang: slugContent } });
    }
  });
  return { paths, fallback: "blocking" };
}

const setNullIfFalsy = (object: any): any => {
  return object ? object : null;
};

const getModularPage = (locale: string, pageName: string): Promise<PageModular> => {
  return PageModularApi.getInstance().getPageModularBySlug(locale, pageName);
};

const getModularStartPage = (locale: string): Promise<PageModular> => {
  const lang = languageConfig.mapLocaleToLanguageShort(locale);
  return PageModularApi.getInstance().getPageModularBySlug(locale, lang);
};

export default Index;
